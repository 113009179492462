import React, { useState, useEffect } from "react";
import {
  List,
  ListItem,
  Divider,
  ListItemText,
  ListItemAvatar,
  Typography,
  Container,
  Box,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { RootState, AppDispatch } from "../../store";

import { getArticles } from "../../_actions/articleActions";
import { useNavigate } from "react-router-dom";
import EmptyData from "../ui-components/EmptyData/EmptyData";

import code_thinking from "../../assets/illustrations/code_thinking.svg";
import CircularLoader from "../ui-components/CircularLoader/CircularLoader";

const Home = () => {
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();

  const articleState = useSelector((state: RootState) => state.articles);
  const { articleList, loading, error } = articleState;

  const [listOfArticles, setListOfArticles] = useState<Article[]>([]);

  interface Article {
    _id: number;
    title: string;
    description: string;
    icon: string;
    createdDate: string;
    modifiedDate: string;
  }

  useEffect(() => {
    dispatch(getArticles());
  }, [dispatch]);

  useEffect(() => {
    if (articleList) {
      setListOfArticles(articleList);
    }
  }, [articleList]);

  const handleOpenArticle = (id: number) => {
    navigate(`/blog/${id}`);
  };

  return (
    <Container maxWidth="md" sx={{ marginTop: -3 }}>
      {loading && <CircularLoader customHeight={250} />}
      {error && (
        <EmptyData
          fileName={code_thinking}
          title={"No Blogs"}
          customHeight="86vh"
        />
      )}

      {!loading && listOfArticles && listOfArticles.length > 0 && (
        <List sx={{ width: "100%" }}>
          {listOfArticles.map((item) => (
            <Box
              key={item._id}
              style={{ cursor: "pointer" }}
              onClick={() => handleOpenArticle(item._id)}
            >
              <ListItem alignItems="flex-start">
                <ListItemAvatar>
                  <img
                    alt="blog icon"
                    src={
                      item.icon
                        ? item.icon
                        : "https://static-00.iconduck.com/assets.00/node-js-icon-454x512-nztofx17.png"
                    }
                    height={50}
                  />
                </ListItemAvatar>
                <ListItemText
                  sx={{ marginLeft: 2 }}
                  primary={
                    <Typography variant="h6" color="text.primary">
                      {item.title}
                    </Typography>
                  }
                  secondary={
                    <>
                      <Typography
                        component="span"
                        variant="body2"
                        sx={{
                          color: "text.primary",
                          display: "-webkit-box",
                          WebkitBoxOrient: "vertical",
                          overflow: "hidden",
                          WebkitLineClamp: 3, // Limits to 3 lines
                          textOverflow: "ellipsis",
                        }}
                      >
                        {item.description}
                      </Typography>
                    </>
                  }
                />
              </ListItem>
              <Divider variant="inset" component="li" />
            </Box>
          ))}
        </List>
      )}
    </Container>
  );
};

export default Home;
