import React, { useState, useEffect } from "react";
import { Typography, Grid, Container, styled } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { RootState, AppDispatch } from "../../store";

import { getArticles } from "../../_actions/articleActions";
import { useNavigate } from "react-router-dom";

import Highlights from "./Highlights";
import Projects from "./Projects";
import profile3 from "../../assets/profile3.jpg";

const Home = () => {
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();

  const articleState = useSelector((state: RootState) => state.articles);
  const { articleList, loading, error } = articleState;

  const [listOfArticles, setListOfArticles] = useState<Article[]>([]);

  interface Article {
    _id: number;
    title: string;
    description: string;
    icon: string;
    createdDate: string;
    modifiedDate: string;
  }

  useEffect(() => {
    dispatch(getArticles());
  }, [dispatch]);

  useEffect(() => {
    console.log(articleList);
    if (articleList) {
      setListOfArticles(articleList);
    }
  }, [articleList, loading, error]);

  const handleOpenArticle = (id: number) => {
    navigate(`/blog/${id}`);
  };

  const CustomLink = styled("span", {
    name: "Link",
    slot: "value",
  })(({ theme }) => ({
    color:
      theme.palette.mode === "dark"
        ? theme.palette.primary.light
        : theme.palette.primary.main,
  }));

  return (
    <Container maxWidth="md">
      <Grid container spacing={2}>
        <Grid item lg={7} md={7} sm={12} xs={12} mt={2}>
          <Typography
            variant="h3"
            sx={{
              fontWeight: 700,
              fontSize: "2.8rem",
              color: (theme) =>
                theme.palette.mode === "dark"
                  ? theme.palette.primary.contrastText
                  : theme.palette.primary.main,
            }}
          >
            Hey, I'm Jayakar
          </Typography>
          <Typography variant="h6" mt={2}>
            I'm a software developer who creates open-source projects and writes
            about coding and life.
          </Typography>
          <Typography variant="h6" mt={2}>
            On this site, you can explore my{" "}
            <CustomLink>technical articles</CustomLink>, read personal notes, or
            learn more <CustomLink>about me</CustomLink>.
          </Typography>
        </Grid>
        <Grid item lg={5} md={5} sm={12} xs={12}>
          <div
            style={{
              width: "-webkit-fill-available",
              borderRadius: 10,
              backgroundImage: `linear-gradient(to bottom, rgba(89, 89, 89, 0.3), rgba(126, 96, 191, 0.75)), url(${profile3})`,
              backgroundSize: "cover",
              color: "#fff",
              padding: 20,
              height: 280,
            }}
          />
        </Grid>
      </Grid>
      <br />
      <Highlights
        listOfArticles={listOfArticles}
        loading={loading}
        error={error}
        handleOpenArticle={handleOpenArticle}
      />{" "}
      <br />
      <br />
      <Projects
        listOfArticles={listOfArticles}
        loading={loading}
        error={error}
        handleOpenArticle={handleOpenArticle}
      />
    </Container>
  );
};

export default Home;
