import React, { useEffect, useState, useRef, Suspense, lazy } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState, AppDispatch } from "../../../store";
import { getArticleByID } from "../../../_actions/articleActions";
import {
  getLikesByArticleID,
  updateLikes,
} from "../../../_actions/publicActions";
import {
  Box,
  Typography,
  Grid,
  Container,
  useTheme,
  IconButton,
  Snackbar,
} from "@mui/material";
import { convertFromRaw, EditorState } from "draft-js";
import Comments from "../../ui-components/Comments/Comments";

import {
  Comment as CommentIcon,
  GitHub as GitHubIcon,
  FavoriteBorder as FavoriteBorderIcon,
  BookmarkBorder as BookmarkBorderIcon,
  Favorite as FavoriteIcon,
} from "@mui/icons-material";
import "./BlogDetail.css";

import page_not_found from "../../../assets/illustrations/page_not_found.svg";

import EmptyData from "../../ui-components/EmptyData/EmptyData";
import CircularLoader from "../../ui-components/CircularLoader/CircularLoader";

const DraftEditor = lazy(() => import("../../DraftEditor/DraftEditor"));

const SideSection = ({
  clapCount,
  handleClap,
  handleCommentClick,
  positionFixed,
  enableFeedback,
  repositoryUrl,
}: {
  clapCount: number;
  handleClap: () => void;
  handleCommentClick: () => void;
  positionFixed: boolean;
  enableFeedback: boolean;
  repositoryUrl: string;
}) => {
  const [showBookmarkPrompt, setShowBookmarkPrompt] = useState(false);

  const handleBookmarkClick = () => {
    setShowBookmarkPrompt(true);
  };

  const handleBookmarkClose = () => {
    setShowBookmarkPrompt(false);
  };

  return (
    <Box
      sx={{
        position: positionFixed ? "fixed" : "relative",
        left: positionFixed ? "2rem" : "0",
        top: positionFixed ? "35%" : "auto",
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "row",
        alignItems: "center",
        zIndex: 1000,
        mt: positionFixed ? 0 : 2,
        mb: positionFixed ? 0 : 2,
      }}
    >
      <Box>
        <IconButton
          onClick={handleClap}
          id="clap-button"
          sx={{
            cursor: "pointer",
          }}
        >
          {clapCount > 0 ? (
            <FavoriteIcon fontSize="small" />
          ) : (
            <FavoriteBorderIcon fontSize="small" />
          )}
        </IconButton>
        <Typography component={"span"} variant="caption">
          {clapCount}
        </Typography>
        {repositoryUrl && (
          <IconButton
            component="a"
            href="https://github.com/Jayakarreddy"
            target="_blank"
            sx={{
              cursor: "pointer",
              marginLeft: 3,
            }}
          >
            <GitHubIcon fontSize="small" />
          </IconButton>
        )}
        {enableFeedback && (
          <IconButton
            onClick={handleCommentClick}
            sx={{
              cursor: "pointer",
            }}
          >
            <CommentIcon sx={{ marginTop: "2px" }} fontSize="small" />
          </IconButton>
        )}
      </Box>
      <Box>
        <IconButton
          onClick={handleBookmarkClick}
          sx={{
            cursor: "pointer",
          }}
        >
          <BookmarkBorderIcon fontSize="small" />
        </IconButton>
      </Box>
      <Snackbar
        color="primary"
        open={showBookmarkPrompt}
        onClose={handleBookmarkClose}
        message="Press Ctrl+D (or Cmd+D on Mac) to bookmark this page."
        autoHideDuration={6000}
      />
    </Box>
  );
};

const BlogDetail = () => {
  const { id } = useParams<{ id: string }>();
  const dispatch: AppDispatch = useDispatch();

  const articleState = useSelector((state: RootState) => state.articles);
  const { articleDetails, loading, error } = articleState;

  const likesState = useSelector((state: RootState) => state.publicInfo);
  const { likesData } = likesState;

  const [content, setContent] = useState<any>(null);
  const clapCountRef = useRef(0);
  const [clapCount, setClapCount] = useState<number>(0);
  const commentsRef = useRef<HTMLDivElement | null>(null);

  const theme = useTheme();

  // Timer ref to handle debounce
  const timerRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (id) {
      dispatch(getArticleByID(id));
      dispatch(getLikesByArticleID(id));
    }
  }, [dispatch, id]);

  useEffect(() => {
    if (likesData && likesData.articleId === id) {
      setClapCount(likesData.likesCount);
      clapCountRef.current = likesData.likesCount;
    }
  }, [likesData, id]);

  useEffect(() => {
    if (
      articleDetails &&
      articleDetails._id === id &&
      articleDetails.articleContent
    ) {
      const steps = articleDetails.articleContent.map((step: any) => {
        const rawContent = {
          blocks: step.articleStep.blocks || [],
          entityMap: step.articleStep.entityMap || {},
        };

        if (rawContent && rawContent.blocks && rawContent.entityMap) {
          const contentState = convertFromRaw(rawContent);
          const editorState = EditorState.createWithContent(contentState);
          return {
            ...step,
            articleStep: editorState,
          };
        } else {
          return step;
        }
      });

      setContent({
        title: articleDetails.title,
        description: articleDetails.description,
        articleContent: steps,
      });
    }
  }, [articleDetails, id]);

  const handleClap = () => {
    clapCountRef.current += 1;
    setClapCount(clapCountRef.current);

    const clapElement = document.getElementById("clap-button");
    if (clapElement) {
      clapElement.classList.add("clap-animation");
      setTimeout(() => {
        clapElement.classList.remove("clap-animation");
      }, 700);
    }

    // Clear previous timer if a new clap happens within the debounce time
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    // Set a new timeout to update likes after the user stops clapping for 2 seconds
    timerRef.current = setTimeout(() => {
      dispatch(updateLikes(id as string, clapCountRef.current)); // Update the likes count in the backend
    }, 2000); // 2-second debounce period
  };

  const handleCommentClick = () => {
    if (commentsRef.current) {
      commentsRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  console.log(articleDetails);

  return (
    <Container maxWidth="md">
      {loading && <CircularLoader />}
      {error && (
        <EmptyData
          fileName={page_not_found}
          title={"Something went wrong, Article is not available"}
          customHeight="86vh"
        />
      )}

      {!loading && content && (
        <>
          <Typography variant="h3" gutterBottom>
            {content.title}
          </Typography>
          <Typography variant="subtitle1" paragraph>
            {content.description}
          </Typography>

          <SideSection
            clapCount={clapCount}
            handleClap={handleClap}
            handleCommentClick={handleCommentClick}
            positionFixed={false}
            enableFeedback={articleDetails.enableFeedback}
            repositoryUrl={articleDetails.repositoryUrl}
          />
          <Suspense
            fallback={
              <Typography variant="body1">Loading editor...</Typography>
            }
          >
            {content.articleContent.map((step: any, index: number) => (
              <Box key={index} sx={{ marginBottom: 3 }}>
                {step.articleStep instanceof EditorState ? (
                  <DraftEditor
                    editorState={step.articleStep}
                    setEditorState={() => {}}
                    readOnly={true}
                  />
                ) : (
                  <Typography variant="body1">
                    Error displaying content.
                  </Typography>
                )}
                {step.articleScreenshots &&
                  step.articleScreenshots.length > 0 && (
                    <Grid container spacing={2} sx={{ marginTop: 2 }}>
                      {step.articleScreenshots.map(
                        (screenshot: string, sIndex: number) => (
                          <Grid item xs={12} key={sIndex}>
                            <img
                              src={screenshot}
                              alt={`Screenshot ${sIndex + 1}`}
                              style={{
                                width: "100%",
                                maxHeight: "450px",
                                objectFit: "fill",
                              }}
                            />
                          </Grid>
                        )
                      )}
                    </Grid>
                  )}
              </Box>
            ))}
          </Suspense>
          <SideSection
            clapCount={clapCount}
            handleClap={handleClap}
            handleCommentClick={handleCommentClick}
            positionFixed={false}
            enableFeedback={articleDetails.enableFeedback}
            repositoryUrl={articleDetails.repositoryUrl}
          />
          {articleDetails.enableFeedback && (
            <Box ref={commentsRef} sx={{ marginTop: 5 }}>
              <Comments
                repo="jayakarreddy/jr-blog-comments"
                issueTerm="pathname"
                theme={
                  theme.palette.mode === "light"
                    ? "github-light"
                    : "github-dark-orange"
                }
              />
            </Box>
          )}
        </>
      )}
    </Container>
  );
};

export default BlogDetail;
